import React from 'react';

import { Page } from 'components/_shared/Page';
import { Profile } from './components';

export const Account = ({ ...props }) => {
  return (
    <Page fullHeight {...props}>
      <Profile />
    </Page>
  );
};

import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { sortBy, round } from 'lodash-es';

import { useGreenhouseActions, selectGreenhouse } from 'state';
import { ROUTES } from '_constants';
import { Button } from 'components/_shared/Button';

import {
  Container,
  StyledTable,
  TableHead,
  SortingButton,
  DeleteButton
} from './GreenhouseManagement.styles';

const SORT_COLUMNS = { New: 'name', Size: 'area_in_ha' };

export const GreenhouseManagement = () => {
  const { deleteLocation, getLocations } = useGreenhouseActions();
  const { locations } = useSelector(selectGreenhouse);
  const [sorting, setSorting] = useState({ column: null, down: false });

  useEffect(() => {
    if (!locations.length) getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedLocations = useMemo(() => {
    if (!sorting.column) return locations;
    const key = SORT_COLUMNS[sorting.column];
    const sorted = sortBy(locations, loc =>
      key === SORT_COLUMNS.New ? loc[key].toLowerCase() : loc[key]
    );
    return sorting.down ? sorted : sorted.reverse();
  }, [locations, sorting]);

  return (
    <Container>
      {sortedLocations.length > 0 && (
        <StyledTable>
          <TableHead>
            <tr>
              <th>Greenhouse list</th>
              <th>Size</th>
              <th>Details</th>
              <th></th>
            </tr>
            <tr>
              {Object.keys(SORT_COLUMNS).map(item => (
                <td key={item}>
                  <SortingButton
                    icon='ArrowUp'
                    down={sorting.column === item && sorting.down}
                    onClick={() =>
                      setSorting(state => ({ column: item, down: !state.down }))
                    }
                  >
                    {item}
                  </SortingButton>
                </td>
              ))}
            </tr>
          </TableHead>
          <tbody>
            {sortedLocations.map(l => (
              <tr key={l.id}>
                <td>{l.name}</td>
                <td>{round(l.area_in_ha, 6)} ha</td>
                <td>
                  <Button
                    variant='primary'
                    to={generatePath(ROUTES.GREENHOUSE_DETAILS, { id: l.id })}
                  >
                    Details
                  </Button>
                </td>
                <td>
                  <DeleteButton variant='primary' onClick={() => deleteLocation(l.id)}>
                    Delete
                  </DeleteButton>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
      <Button variant='primary' to={ROUTES.NEW_GREENHOUSE}>
        Add new greenhouse
      </Button>
    </Container>
  );
};

import styled, { css } from 'styled-components';
import { rem } from 'styles';

export const StyledLogo = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.primary.p1};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(90)};
    height: auto;

    svg {
      color: inherit;
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
    img {
      max-width: 50%;
    }

    ${theme.breakpoints.xs(
      css`
        width: ${rem(70)};
      `
    )};
  `}
`;

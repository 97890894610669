import styled, { css } from 'styled-components';
import { rem, em } from 'styles';
import { Link } from 'react-router-dom';
import { Menu } from 'components/_shared/Menu';
import { MenuDropdown, MenuToggle } from 'components/_shared/Menu/Menu.styles';

const breakpoint = '992px';
const activePage = css`
  border-bottom: 1px solid;
`;

export const MenuItem = styled.li`
  ${({ theme, isActive }) => [
    css`
      font-size: ${rem(14)};
      color: ${theme.colors.white};
      cursor: pointer;
      margin-right: ${em(24)};

      &:hover {
        color: ${theme.colors.primary.p3};
      }
    `,
    isActive && activePage
  ]}
`;

export const MenuLink = styled(Link)`
  ${({ theme, isActive }) => [
    css`
      text-decoration: none;
      color: ${theme.colors.white};
      &:hover {
        color: ${theme.colors.primary.p3};
      }

      @media (max-width: ${breakpoint}) {
        color: ${theme.colors.black};
        &:hover {
          color: ${theme.colors.primary.p1};
        }
      }
    `,
    isActive && activePage
  ]}
`;

export const StyledMenu = styled.ul`
  display: flex;
  margin-right: auto;

  @media (max-width: ${breakpoint}) {
    display: none;
  }
`;

export const MobileMenu = styled(Menu)`
  display: none;
  flex-grow: 1;
  @media (max-width: ${breakpoint}) {
    display: block;
  }
  ${MenuDropdown} {
    position: fixed;
    left: 0;
    top: ${rem(50)};
    min-width: ${rem(300)};
    opacity: 0.9;
  }
  svg {
    width: ${rem(24)};
  }
`;

export const SettingMenu = styled(Menu)`
  ${MenuDropdown} {
    top: ${rem(20)};
    right: 0;
  }
  ${MenuToggle} {
    ${({ theme }) =>
      theme.breakpoints.xs(
        css`
          span {
            max-width: ${rem(180)};
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        `
      )};
  }
`;

import { axiosInstance } from 'api/instance';
import { varietyEndpoints } from './variety.endpoints';

const getVarietyChart = (locationId, start, end) =>
  axiosInstance.get(varietyEndpoints.chart_data(locationId), {
    params: {
      start_date: start,
      end_date: end
    }
  });

const getVarietyMap = mapId => axiosInstance.get(varietyEndpoints.map(mapId));

export const varietyRequests = {
  getVarietyChart,
  getVarietyMap
};

import React from 'react';

import { StyledInputText, Wrapper, Label } from './InputText.styles';

export const InputText = ({ type = 'text', ...props }) => {
  return <StyledInputText {...props} type={type} />;
};

export const Input = ({ type = 'text', label, className, fullWidth, ...props }) => {
  return (
    <Wrapper className={className} fullWidth={fullWidth}>
      {label && <Label>{label}</Label>}
      <StyledInputText {...props} type={type} />
    </Wrapper>
  );
};

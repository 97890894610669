import styled, { css } from 'styled-components';
import { em, rem } from 'styles';

import { Paper } from 'components/_shared/Paper';
import { Input } from 'components/_shared/Input';
import { Button } from 'components/_shared/Button';

const breakpoint = '768px';

export const Container = styled.div`
  display: flex;
  gap: ${em(14)};
  @media (max-width: ${breakpoint}) {
    display: block;
  }
`;

export const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${em(20)};
  flex-grow: 1;
`;

export const Filters = styled(Paper)`
  padding: ${em(10)};
  width: ${em(320)};
  @media (max-width: ${breakpoint}) {
    width: 100%;
  }
`;

export const DateInput = styled(Input)`
  margin: ${em(6)} 0;
  & input {
    font-size: ${em(14)};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${em(12)};
  margin-top: ${em(30)};
`;

export const FilterButton = styled(Button)`
  ${({ theme, variantType }) => [
    css`
      border-radius: ${rem(theme.radius[1])};
    `,
    variantType === 'danger' &&
      css`
        color: ${theme.colors.danger};
      `
  ]}
`;

export const HelpText = styled.p`
  text-align: center;
  margin: 10px auto;
  color: ${({ theme }) => theme.colors.nature.n3};
`;

export const SelectOption = styled.span`
  ${({ isActive }) => [
    css`
      font-size: ${em(14)};
    `,
    !isActive &&
      css`
        color: #ef9090;
      `
  ]}
`;

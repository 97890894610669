import React, { forwardRef } from 'react';

import { ButtonIcon, ButtonBody, StyledButton, StyledLink } from './Button.styles';

export const Button = forwardRef(
  (
    {
      children,
      type = 'button',
      icon,
      variantType,
      fullWidth = false,
      iconPosition = 'start',
      ...props
    },
    ref
  ) => {
    const Tag = props.to ? StyledLink : StyledButton;

    return (
      <Tag
        {...props}
        ref={ref}
        hasChildren={!!children}
        type={type}
        icon={icon}
        variantType={variantType}
        fullWidth={fullWidth}
        iconPosition={iconPosition}
      >
        {icon && <ButtonIcon>{icon}</ButtonIcon>}
        {children && <ButtonBody>{children}</ButtonBody>}
      </Tag>
    );
  }
);

import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '_constants';
import { useUserActions } from 'state';
import { Button } from 'components/_shared/Button';
import { MenuItem, StyledMenu, MenuLink, MobileMenu, SettingMenu } from './Menu.styles';

export const Menu = () => {
  const { pathname } = useLocation();

  return (
    <>
      <StyledMenu>
        <MenuItem isActive={pathname === ROUTES.ROOT}>
          <MenuLink to={ROUTES.ROOT}>Dashboard</MenuLink>
        </MenuItem>
        <MenuItem isActive={pathname.includes('greenhouse')}>
          <MenuLink to={ROUTES.GREENHOUSE}>Greenhouses</MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink to={ROUTES.ROOT}>Scanning</MenuLink>
        </MenuItem>
      </StyledMenu>
      <MobileMenu toggleIcon='List'>
        <MenuLink to={ROUTES.ROOT} isActive={pathname === ROUTES.ROOT}>
          Dashboard
        </MenuLink>
        <MenuLink isActive={pathname.includes('greenhouse')} to={ROUTES.GREENHOUSE}>
          Greenhouses
        </MenuLink>
        <MenuLink to={ROUTES.ROOT}>Scanning</MenuLink>
      </MobileMenu>
    </>
  );
};

export const ProfileMenu = ({ text }) => {
  const { toggleLogoutModal } = useUserActions();

  return (
    <SettingMenu toggleIcon='LogOut' toggleText={text} iconPosition='end'>
      <Button to={ROUTES.ACCOUNT} icon='Settings'>
        Profile
      </Button>
      <Button icon='LogOut' onClick={toggleLogoutModal}>
        Log Out
      </Button>
    </SettingMenu>
  );
};

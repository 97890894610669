export function getISOWeekDateRange(week, year) {
  if (!week || !year) return '';
  const simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
  const dayOfWeek = simple.getUTCDay();
  const ISOWeekStart = new Date(simple);
  ISOWeekStart.setUTCDate(simple.getUTCDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
  const ISOWeekEnd = new Date(ISOWeekStart);
  ISOWeekEnd.setUTCDate(ISOWeekStart.getUTCDate() + 6);
  const formatDate = date => date.toISOString().split('T')[0];
  return {
    start: formatDate(ISOWeekStart),
    end: formatDate(ISOWeekEnd)
  };
}

export function getFirstDateOfYear(year) {
  if (!year) return null;
  const date = new Date(year, 0, 1);
  const yearStr = date.getFullYear();
  const monthStr = String(date.getMonth() + 1).padStart(2, '0');
  const dayStr = String(date.getDate()).padStart(2, '0');
  return `${yearStr}-${monthStr}-${dayStr}`;
}

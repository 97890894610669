import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { range, uniq } from 'lodash-es';
import { selectGreenhouse } from 'state';
import { Map } from 'components/Map';
import { Select } from 'components/_shared/Select';
import { varietyRequests } from 'api';
import { getISOWeekDateRange, getFirstDateOfYear } from './helpers';
import {
  Container,
  ChartsContainer,
  Filters,
  DateInput,
  FilterButton,
  ButtonWrapper,
  HelpText,
  SelectOption
} from './CanopyDetection.styles';

const START_YEAR = 2024;
const yearsRange = range(START_YEAR, new Date().getFullYear() + 1);

export const CanopyDetection = () => {
  const { selectedGreenhouse } = useSelector(selectGreenhouse);
  const [selectedVariety, setSelectedVariety] = useState();
  const [filterState, setFilterState] = useState({});
  const [locations, setLocations] = useState([]);
  const [chart, setChart] = useState([]);
  const [filteredChart, setFilteredChart] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    varietyRequests
      .getVarietyMap(selectedGreenhouse)
      .then(({ data }) => setLocations(data));
  }, [selectedGreenhouse]);

  const dateRange = useMemo(
    () => getISOWeekDateRange(filterState.week, filterState.year),
    [filterState.week, filterState.year]
  );

  const missions = useMemo(
    () =>
      filteredChart.reduce((acc, curr) => {
        const name = curr.properties.mission_name;
        acc.hasOwnProperty(name) ? acc[name].push(curr) : (acc[name] = [curr]);
        return acc;
      }, {}),
    [filteredChart]
  );

  const missionSelectItems = useMemo(
    () => [
      { name: 'All missions', value: '' },
      ...Object.keys(missions).map(m => ({
        name: m,
        value: m
      }))
    ],
    [missions]
  );
  // dates for weeks
  const missionDates = useMemo(
    () => uniq(chart.map(x => x.properties.mission_date.split(' ')[0])),
    [chart]
  );

  const weekSelectItems = useMemo(() => {
    if (!missionDates.length) return [];

    function mapCB(_, i) {
      const { start, end } = getISOWeekDateRange(i + 1, filterState.year);
      const hasData = missionDates.some(x => {
        const startDate = new Date(start);
        const currentDate = new Date(x);
        const endDate = new Date(end);
        return startDate <= currentDate && currentDate <= endDate;
      });
      const option = `${i + 1} week (${start} - ${end})`;
      return {
        name: <SelectOption isActive={hasData}>{option}</SelectOption>,
        value: i + 1
      };
    }

    return [...Array(52)].map(mapCB);
  }, [filterState.year, missionDates]);

  const getChartData = (greenhouse, startDate, endDate, cb) => {
    setFilteredChart([]); // clear data to rerender map
    setLoading(true);
    varietyRequests
      .getVarietyChart(greenhouse, startDate, endDate)
      .then(({ data }) => {
        cb?.(data);
        setFilteredChart(data);
      })
      .finally(() => setLoading(false));
  };

  const handleApplyFilter = () => {
    setLoading(true);
    const { day, year } = filterState;
    const startDate = day || (dateRange?.start ?? getFirstDateOfYear(year));
    const endDate = day || dateRange?.end;
    getChartData(selectedGreenhouse, startDate, endDate);
  };

  const handleClearFilter = () => {
    getChartData(selectedGreenhouse);
    setFilterState({ year: filterState.year });
  };

  const handleLocationChange = async ({ value }) => {
    setSelectedVariety(value);
    setFilterState({});
  };

  const handleYearChange = ({ value }) => {
    if (value === filterState.year) return;
    const firstWeek = getISOWeekDateRange(1, value);
    setFilterState({
      ...filterState,
      year: value,
      day: '',
      week: '',
      mission: ''
    });
    getChartData(selectedGreenhouse, firstWeek.start, `${value}-12-31`, setChart);
  };

  const handleWeekChange = ({ value }) => {
    setFilterState({ ...filterState, week: value, day: '', mission: '' });
    const { start, end } = getISOWeekDateRange(value, filterState.year);
    getChartData(selectedGreenhouse, start, end);
  };

  const handleDateChange = ({ target }) => {
    setFilterState({
      ...filterState,
      day: target.value,
      mission: ''
    });
  };

  return (
    <Container>
      <Filters>
        <Select
          items={locations.map(l => ({ name: l.name, value: l.id }))}
          onSelect={handleLocationChange}
          value={selectedVariety}
          placeholder='Select variety map'
          label='Select variety map'
        />
        {selectedVariety && (
          <>
            <Select
              items={yearsRange.map(v => ({ name: v, value: v }))}
              onSelect={handleYearChange}
              value={filterState.year}
              placeholder='All Years'
              label='Year'
            />
            {filterState.year && (
              <Select
                items={weekSelectItems}
                onSelect={handleWeekChange}
                value={filterState.week}
                placeholder='Select Week'
                label='Week'
              />
            )}
            <DateInput
              type='date'
              label='Day'
              fullWidth
              value={filterState.day ?? ''}
              onChange={handleDateChange}
              min={dateRange?.start ?? getFirstDateOfYear(START_YEAR)}
              max={dateRange?.end}
            />
            {filteredChart.length > 0 && (
              <Select
                items={missionSelectItems}
                onSelect={({ value }) =>
                  setFilterState({ ...filterState, mission: value })
                }
                value={filterState.mission}
                placeholder='Select Mission'
                label='Mission'
              />
            )}
          </>
        )}
        <ButtonWrapper>
          <FilterButton
            fullWidth
            variant='secondary'
            variantType='danger'
            icon='Delete'
            onClick={handleClearFilter}
            disabled={!selectedVariety || loading}
            title={!selectedVariety ? 'Select variety' : ''}
          >
            Clear filter
          </FilterButton>
          <FilterButton
            fullWidth
            variant='primary'
            onClick={handleApplyFilter}
            disabled={!selectedVariety || loading}
            title={!selectedVariety ? 'Select variety' : ''}
          >
            {loading ? 'Loading...' : 'Apply'}
          </FilterButton>
        </ButtonWrapper>
      </Filters>
      {filteredChart.length > 0 && (
        <ChartsContainer>
          <Map
            data={filterState.mission ? missions[filterState.mission] : filteredChart}
            backgroundData={locations.find(({ id }) => id === +selectedVariety)?.map}
          />
        </ChartsContainer>
      )}
      {(!selectedVariety || filteredChart.length === 0) && (
        <HelpText>{loading ? 'Loading...' : 'Set filter and apply'}</HelpText>
      )}
    </Container>
  );
};

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'components/_shared/Icon';
import { Divider } from 'components/_shared/Divider';
import { useGreenhouseActions, selectGreenhouse } from 'state';

import {
  FlexWrap,
  GreenhouseWrapper,
  Title,
  GreenhouseSelect
} from './GreenhouseList.styles';

export const GreenhouseList = () => {
  const { getLocations, setSelectedGreenhouse } = useGreenhouseActions();
  const { locations, selectedGreenhouse } = useSelector(selectGreenhouse);

  useEffect(() => {
    if (!locations.length) getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GreenhouseWrapper>
        <FlexWrap>
          <Icon>House</Icon>
          <Title>Greenhouse</Title>
        </FlexWrap>
        <GreenhouseSelect
          items={locations.map(x => ({ name: x.name, value: x.id }))}
          placeholder='Select greenhouse'
          onSelect={({ value }) => setSelectedGreenhouse(value)}
          value={locations.find(x => x.id === selectedGreenhouse)?.id}
        />
      </GreenhouseWrapper>
      <Divider />
    </>
  );
};

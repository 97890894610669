import styled, { css } from 'styled-components';
import { em } from 'styles';

import { Select } from 'components/_shared/Select';

export const GreenhouseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${em(10)};
  padding: ${em(10)};
  ${({ theme }) =>
    theme.breakpoints.xs(
      css`
        flex-direction: column;
        align-items: flex-start;
      `
    )};
`;

export const FlexWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.span`
  margin-left: ${em(10)};
  margin-right: ${em(25)};
`;

export const GreenhouseSelect = styled(Select)`
  flex-grow: 1;
  max-width: 500px;
  ${({ theme }) =>
    theme.breakpoints.xs(
      css`
        max-width: 100%;
        width: 100%;
      `
    )};
`;

import styled, { css } from 'styled-components';
import { em, rem } from 'styles';
import { MapContainer } from 'react-leaflet';
import { Paper } from 'components/_shared/Paper';
import { Button } from 'components/_shared/Button';

export const StyledMapContainer = styled(MapContainer)`
  ${({ theme }) => css`
    height: ${rem(420)};
    max-width: ${em(1500)};
    min-width: ${em(300)};
    border: 1px solid ${theme.colors.primary.p3};
    border-radius: 10px;
    background: ${theme.colors.white};
    .leaflet-bottom.leaflet-right {
      display: none;
    }
  `}
`;

export const DetailInfo = styled.div`
  text-align: left;
  width: 35%;
`;

export const MapBlock = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${rem(10)};
`;

export const SidebarList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.nature.n4};
`;

export const SidebarItem = styled.li`
  display: flex;
  gap: 10px;
  font-size: ${rem(12)};
  font-weight: 400;
`;

export const SidebarKey = styled.strong`
  flex: 1;
  text-transform: capitalize;
  font-weight: 500;
  max-width: 40%;
  color: ${({ theme }) => theme.colors.black};
`;

export const SidebarValue = styled.span`
  flex: 2;
  word-break: break-word;
`;

export const ColorBlock = styled.div`
  width: 40px;
  height: 15px;
  border-radius: 4px;
  border: 1px solid;
  opacity: 0.5;
`;

export const Popup = styled(Paper)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${({ theme }) => theme.zIndexes[2]};
  width: 500px;
  opacity: 0.95;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: ${rem(10)};
  right: ${rem(10)};
`;

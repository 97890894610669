import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Page } from 'components/_shared/Page';
import { GreenhouseDetails as GreenhouseDetailsContent } from 'components/GreenhouseDetails';
import { selectGreenhouse, useGreenhouseActions } from 'state';

export const GreenhouseDetails = props => {
  const { id } = useParams();
  const { locations, subscriptions } = useSelector(selectGreenhouse);
  const { loadSubscriptions, getLocations } = useGreenhouseActions();

  useEffect(() => {
    if (!locations.length) getLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!subscriptions) loadSubscriptions();
  }, [subscriptions, loadSubscriptions]);

  const greenhouse = useMemo(
    () => locations.find(location => location.id === Number(id)),
    [id, locations]
  );
  const subscription = useMemo(
    () => subscriptions?.find(({ id }) => id === greenhouse?.billing_plan),
    [greenhouse?.billing_plan, subscriptions]
  );

  return (
    <Page fullHeight {...props}>
      {greenhouse && (
        <GreenhouseDetailsContent greenhouse={greenhouse} subscription={subscription} />
      )}
    </Page>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '_constants';
import logo from 'assets/images/grower-adviser-logo-white.png';

import { StyledLogo } from './Logo.styles';

export const Logo = ({ ...props }) => {
  return (
    <Link to={ROUTES.ROOT}>
      <StyledLogo {...props}>
        <img src={logo} alt='Logo' />
      </StyledLogo>
    </Link>
  );
};

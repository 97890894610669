import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectGreenhouse } from 'state';
import { DATA_NOT_AVAILABLE } from '_constants';
import { Paper } from 'components/_shared/Paper';
import { GreenhouseList } from 'components/GreenhouseList';
import { Tabs, Tab, TabContainer, TabsLine } from './Dashboard.styles';
import { Header } from 'components/Header';
import { CanopyDetection } from 'components/CanopyDetection';

const tabNames = ['Canopy coverage graph', 'Heat Map'];
export const Dashboard = () => {
  const user = useSelector(selectUser);
  const { selectedGreenhouse } = useSelector(selectGreenhouse);
  const [selectedTab, setSelectedTab] = useState(tabNames[0]);

  const userToken = user?.token;
  const customerId = user?.customer?.[0]?.external_id;

  function renderDashboard(tab) {
    switch (tab) {
      case tabNames[0]:
        return (
          <iframe
            width='100%'
            height='800px'
            src={`https://portal.groweradviser.com/streamlit_proxy/?user_token=${userToken}&customer_id=${customerId}&location_id=${selectedGreenhouse}`}
          ></iframe>
        );
      case tabNames[1]:
        return <CanopyDetection />;
      default:
        return <Paper>{DATA_NOT_AVAILABLE}</Paper>;
    }
  }

  return (
    <>
      <Header />
      <GreenhouseList />
      <TabContainer padding={1}>
        <Tabs>
          {tabNames.map(v => (
            <Tab key={v} onClick={() => setSelectedTab(v)} selected={selectedTab === v}>
              {v}
            </Tab>
          ))}
          <TabsLine />
        </Tabs>
        {user && selectedGreenhouse && renderDashboard(selectedTab)}
      </TabContainer>
    </>
  );
};

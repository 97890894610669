import styled, { css } from 'styled-components';
import { em, rem } from 'styles';
import { Option, Select, SelectToggleText } from 'components/_shared/Select';
import { Paper } from 'components/_shared/Paper';

export const StyledSelect = styled(Select)`
  width: ${em(200)};
  margin: ${em(10)};
  ${SelectToggleText} {
    text-transform: capitalize;
  }
  ${Option} {
    text-transform: capitalize;
  }
`;

export const TabContainer = styled(Paper)`
  border-radius: 0;
`;

export const Tabs = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: ${em(10)};
  font-size: ${em(14)};
`;

export const TabsLine = styled.div`
  height: 1px;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.primary.p2};
`;

export const Tab = styled.div`
  ${({ theme, selected }) => [
    css`
      padding: ${em(5)} ${em(10)};
      cursor: pointer;
      color: ${selected ? theme.colors.primary.p1 : theme.colors.white};
      background-color: ${selected ? theme.colors.white : theme.colors.primary.p1};
      border: 1px solid ${theme.colors.primary.p2};
      border-top-left-radius: ${rem(theme.radius[1])};
      border-top-right-radius: ${rem(theme.radius[1])};
      text-transform: capitalize;
      transition: ${theme.transitions.fast};
      &:not(&:first-child) {
        margin-left: 1px;
      }
      &:hover {
        background-color: ${theme.colors.primary.p2};
        color: ${theme.colors.white};
      }
    `,
    selected &&
      css`
        border-bottom: 1px solid transparent;
      `
  ]}
`;

import styled, { css } from 'styled-components';

import { rem } from 'styles';
import { shouldForwardProp } from 'utils';

import { Button } from '../Button';
import { Dropdown } from '../Dropdown';

export const MenuListItem = styled.li`
  margin-bottom: ${rem(6)};
`;

export const MenuList = styled.ul``;

export const MenuDropdown = styled(Dropdown)`
  min-width: ${rem(150)};
`;

export const MenuToggle = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${rem(14)};
  `}
  svg {
    width: ${rem(14)};
  }
`;

export const StyledMenu = styled.div.withConfig({ shouldForwardProp })`
  ${({ theme }) =>
    css`
      position: relative;
      display: flex;

      &:hover,
      &.isOpen {
        ${MenuToggle} {
          color: ${theme.colors.primary.p2};
        }
      }
    `}
`;

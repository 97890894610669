import { authRequests } from './auth';
import { usersRequests } from './users';
import { greenhouseRequests } from './greenhouse';
import { varietyRequests } from './variety';

export const API = {
  auth: authRequests,
  users: usersRequests,
  greenhouse: greenhouseRequests,
  variety: varietyRequests
};

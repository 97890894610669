export const VIEWPORT_PADDING = 20;

export const LEGEND_LABEL = 'Labels';

export const DATA_NOT_AVAILABLE = 'Data not available';

export const MODAL_TYPE = {
  DELETE: 'delete',
  SAVE: 'save',
  SAVE_FIELD: 'save_field'
};

export const FIELD_VALIDATION = {
  PASSWORD: 'Password must be at least 8 characters',
  PASSWORD_MATCH: 'Passwords must match',
  REQUIRED: 'This field is required',
  MIN_NUMBER: 'Value should be greater'
};

export const EMAIL_TEXT = {
  error: 'Failed to send your message, please try again later',
  success: 'Your message has been successfully sent'
};

export const EMAIL_VARIABLES = {
  name: 'name',
  email: 'email',
  message: 'message',
  id: 'id'
};

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const USER_PERMISSIONS = {
  ADD: 'add_location',
  CHANGE: 'change_location',
  DELETE: 'delete_location'
};

export const HA_TO_METERS_FACTOR = 10000;

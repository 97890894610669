import React, { useState, useRef, useEffect } from 'react';
import 'leaflet-editable';
import { CRS } from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';

import { selectGreenhouse, useGreenhouseActions } from 'state';
import { themes } from 'styles';

import {
  StyledMapContainer,
  DetailInfo,
  MapBlock,
  SidebarList,
  SidebarItem,
  SidebarKey,
  SidebarValue,
  ColorBlock,
  Popup,
  CloseButton
} from './Map.styles';

const selectedFeatureColor = themes.themeDefault.colors.primary.p2;

export const Map = ({ data, backgroundData, ...props }) => {
  const { selectedZone } = useSelector(selectGreenhouse);
  const { setSelectedZone } = useGreenhouseActions();
  const backgroundGeoJsonRef = useRef(null);
  const dataGeoJsonRef = useRef(null);
  const [map, setMap] = useState(null);
  const [key, setKey] = useState(0);
  const [sidebarContent, setSidebarContent] = useState(null);
  const featureColors = useRef({});

  const geojsonEvents = {
    add: e => map.fitBounds(e.target.getBounds())
  };

  const interpolateColor = percent => {
    const red = Math.min(255, (1 - percent / 100) * 255);
    const green = Math.min(255, (percent / 100) * 255);
    return `rgb(${Math.floor(red)}, ${Math.floor(green)}, 0)`;
  };

  const geojsonFeatureHandler = (feature, layer) => {
    layer.on({
      click: ({ target }) => {
        if (!target.feature.properties.UUID) return;
        setSelectedZone(target.feature.properties.UUID);

        const clickedFeature = target.feature;

        let intersectingFeatures = [];

        if (data) {
          data.forEach(dataFeature => {
            try {
              const clickedPolygon = turf.polygon(clickedFeature.geometry.coordinates);
              const bgPolygon = turf.polygon(dataFeature.geometry.coordinates);

              if (turf.intersect(turf.featureCollection([clickedPolygon, bgPolygon]))) {
                intersectingFeatures.push(dataFeature.properties);
              }
            } catch (error) {
              console.error('Error checking intersection:', error);
            }
          });
        }

        setSidebarContent({
          ...clickedFeature.properties,
          ...Object.assign({}, ...intersectingFeatures)
        });
      }
    });
    layer.setStyle({
      color: layer.feature.properties.color,
      fillColor: layer.feature.properties.color,
      fillOpacity: 0.1,
      opacity: 0.15,
      weight: 1
    });
  };
  const dataFeatureHandler = (feature, layer) => {
    const polygonId = feature.properties.polygon_id;
    if (!polygonId) return;
    if (!featureColors.current[polygonId]) {
      featureColors.current[polygonId] = interpolateColor(
        feature.properties.canopy_percentage
      );
    }
    layer.setStyle({
      color: featureColors.current[polygonId],
      fillColor: featureColors.current[polygonId],
      fillOpacity: 0.6,
      opacity: 0.6,
      weight: 0
    });
  };

  useEffect(() => setKey(x => x + 1), [data]);

  useEffect(() => {
    if (!backgroundGeoJsonRef.current || !map) return;

    backgroundGeoJsonRef.current.eachLayer(layer => {
      const uuid = layer.feature.properties.UUID;
      if (!uuid) return;

      if (uuid === selectedZone) {
        layer.setStyle({
          color: selectedFeatureColor,
          opacity: 0.8,
          weight: 1
        });
        layer.bringToFront();
      } else {
        layer.setStyle({
          color: layer.feature.properties.color,
          fillColor: layer.feature.properties.color,
          fillOpacity: 0.1,
          opacity: 0.15,
          weight: 1
        });
      }
    });
  }, [selectedZone, map]);

  useEffect(() => {
    if (
      !backgroundGeoJsonRef.current ||
      !backgroundGeoJsonRef.current.leafletElement ||
      !map ||
      !backgroundData
    )
      return;

    const geojsonLayer = backgroundGeoJsonRef.current.leafletElement;
    const bounds = geojsonLayer.getBounds();
    const center = bounds.getCenter();
    map.setView(center, map.getZoom());
  }, [backgroundData, map]);

  return (
    <MapBlock>
      <StyledMapContainer
        editable={false}
        center={[50, 50]}
        zoom={0}
        scrollWheelZoom={true}
        zoomControl={true}
        whenCreated={setMap}
        crs={CRS.Simple}
        minZoom={0}
        maxZoom={8}
        {...props}
      >
        {map && data && (
          <GeoJSON
            key={key}
            data={data}
            ref={dataGeoJsonRef}
            eventHandlers={geojsonEvents}
            onEachFeature={dataFeatureHandler}
          />
        )}
        {map && backgroundData && (
          <GeoJSON
            key={`bg-${key}`}
            data={backgroundData}
            // style={getBackgroundLayerStyle}
            ref={backgroundGeoJsonRef}
            onEachFeature={geojsonFeatureHandler}
          />
        )}
      </StyledMapContainer>
      <DetailInfo>
        {sidebarContent && (
          <Popup>
            <CloseButton icon='Cross' onClick={() => setSidebarContent(null)} />
            <SidebarList>
              {Object.entries(sidebarContent)
                .filter(([key]) => !['variety_name', 'polygon_id', 'UUID'].includes(key))
                .map(([key, value]) => (
                  <SidebarItem key={key}>
                    <SidebarKey>{key.replace(/_/g, ' ')}:</SidebarKey>
                    {key === 'color' ? (
                      <SidebarValue>
                        <ColorBlock style={{ backgroundColor: value }} title={value} />
                      </SidebarValue>
                    ) : (
                      <SidebarValue>
                        {Array.isArray(value)
                          ? value
                              .map(v =>
                                typeof v === 'number' && !Number.isInteger(v)
                                  ? v.toFixed(2)
                                  : v
                              )
                              .join(', ')
                          : typeof value === 'number' && !Number.isInteger(value)
                          ? value.toFixed(2)
                          : value}
                      </SidebarValue>
                    )}
                  </SidebarItem>
                ))}
            </SidebarList>
          </Popup>
        )}
      </DetailInfo>
    </MapBlock>
  );
};
